<template>
   <div class="row justify-content-center align-items-center py-5">
      <div class="col-12 text-center">
        <img
          class="error-image"
          src="@/assets/images/unKnownError.png"
          alt=""
        />
        <h3 class="text-secondary mt-4">
          {{$tc("message.serverErrorMessage", 1)}}
        </h3>
        </div>
    </div>
</template>

<script>
  export default {
    data () {
      return {

      }
    }
  }    
</script>
<style scoped>
.error-image {
  width: 70px;
  height: auto;
}
</style>